import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import QuotationModal from './Utils/QuotationModal';
import { JobDetails } from './partials/JobDetails';
import { CartItemsList } from './partials/CartItemsList';
import { NewItemForm } from './partials/NewItemForm';
import { TotalsDisplay } from './partials/TotalsDisplay';
import {MarginAnalysis } from './partials/MarginAnalysis';
import BreadCrumb from '../BreadCrumb';


const pages = [
 
    { name: 'Finance', to: '/finance', component: Link, current: false },
    { name: 'Quotations', to: '/finance/quotations', component: Link, current: false },
    { name: 'Create New Quotation', to: '/finance/quotationpage', component: Link, current: true },
  ];


const QuotationPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [includeTax, setIncludeTax] = useState(false);
  const [services, setServices] = useState([]);
   const [marginSettings, setMarginSettings] = useState({
    
    default: { serviceMargin: 0, partsMargin: 0 },
    vehicleDefaultMargin: 0,
    serviceDefaultMargin: 0,
    vehicles: [],
    services: []
  });
  const [newPart, setNewPart] = useState({ partName: '', quantity: 1, type: 'generic' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totals, setTotals] = useState({
    totalCost: 0,
    subtotal: 0,
    totalSale: 0,
    taxAmount: 0
  });
  const [marginPercentage, setMarginPercentage] = useState(40); // Default 40%
  


  useEffect(() => {
    const fetchJobs = async () => {
      const jobsCollectionRef = collection(db, 'jobs');
      const jobsSnapshot = await getDocs(jobsCollectionRef);
      
      const jobsList = jobsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        // Ensure all cost prices are numbers
        partsRequired: doc.data().partsRequired.map(part => ({
          ...part,
          bestCostPrice: Number(part.bestCostPrice),
          worstCostPrice: Number(part.worstCostPrice)
        })),
        servicesRequired: doc.data().servicesRequired?.map(service => ({
          ...service,
          bestCostPrice: Number(service.bestCostPrice),
          worstCostPrice: Number(service.worstCostPrice)
        }))
      }));
      

      setJobs(jobsList);
    };

    fetchJobs();
  }, []);


  const fetchMarginSettings = useCallback(async () => {
    try {
      const settingsSnapshot = await getDocs(collection(db, 'marginSettings'));
      const fetchedSettings = settingsSnapshot.docs.reduce((acc, docSnap) => {
        const setting = { id: docSnap.id, ...docSnap.data() };
        switch (setting.type) {
          case 'default':
            acc.default = { ...acc.default, ...setting };
            break;
          case 'default-vehicle':
            acc.vehicleDefaultMargin = setting.margin;
            break;
          case 'default-service':
            acc.serviceDefaultMargin = setting.margin;
            break;
          case 'vehicles':
            acc.vehicles.push(setting);
            break;
          case 'services':
            acc.services.push(setting);
            break;
          default:
            break;
        }
        return acc;
      }, marginSettings);
      setMarginSettings(fetchedSettings);
    } catch (error) {
      toast.error("Error fetching margin settings: " + error.message);
    }
  }, [marginSettings]); 
  

  useEffect(() => {
    if (selectedJob) {
      const updatedCartItems = [
        ...(selectedJob.partsRequired || []).map(part => ({
          partName: part.partName,
          quantity: part.quantity,
          costPrice: part.bestCostPrice, 
          worstPrice : part.worstCostPrice,
          salePrice: '', 
          type: 'part', 
        })),
        ...(selectedJob.servicesRequired || []).map(service => ({
          partName: service.serviceType,
          quantity: service.serviceQuantity,
          costPrice: service.bestCostPrice, 
          worstPrice : service.worstCostPrice || service.serviceCost,
          salePrice: '', 
          type: 'service', 
        }))
      ];
      setCartItems(updatedCartItems);
    }
    fetchMarginSettings();
  }, [selectedJob, fetchMarginSettings]);
  


  const jobOptions = jobs.map(job => ({
    value: job.id, 
    label: ` ${job.jobCardNo} - ${job.vehicleMake} ${job.vehicleModel} ${job?.registrationNumber}` 
  }));

  const handleJobChange = selectedOption => {
    const job = jobs.find(job => job.id === selectedOption?.value);
    setSelectedJob(job); 
  };
  

  const handleTypeChange = (index, value) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].type = value;
    setCartItems(updatedCartItems);
  };

  const calculateTotals = useCallback(() => {
    let subtotal = 0;
    let totalSale = 0;
    let totalCost = 0;
  
    cartItems.forEach(item => {
      const itemTotal = (item.salePrice || 0) * item.quantity;
      subtotal += itemTotal;
      totalCost += (item.costPrice || 0) * item.quantity;
    });
  
    if (newPart.costPrice && newPart.salePrice) {
      const newPartTotal = newPart.salePrice * newPart.quantity;
      subtotal += newPartTotal;
      totalCost += newPart.costPrice * newPart.quantity;
    }
  
    let taxAmount = 0;
    if (includeTax) {
      const taxRate = 0.075; // Assuming a tax rate of 7.5%
      taxAmount = subtotal * taxRate;
      totalSale = subtotal + taxAmount;
    } else {
      totalSale = subtotal; 
    }
  
    return { totalCost, subtotal, totalSale, taxAmount };
  }, [cartItems, newPart, includeTax]); 
  

  const updateTotals = useCallback(() => {
    const calculatedTotals = calculateTotals();
    setTotals(calculatedTotals);
  }, [calculateTotals]); 
  
  
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesSnapshot = await getDocs(collection(db, 'services')); // Adjust the collection name as needed
        const servicesList = servicesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesList); // Set fetched services to state
      } catch (error) {
        toast.error("Error fetching services: " + error.message);
      }
    };
  
    fetchServices();
  }, []);
  
  useEffect(() => {
    updateTotals();
  }, [cartItems, newPart, includeTax, updateTotals]);
 
  const calculateSalePrice = (item) => {
    return item.costPrice * (1 + marginPercentage / 100);
  };
  
  const calculateNewPartSalePrice = () => {
    return newPart.costPrice * (1 + marginPercentage / 100);
  };
  
 
  const handleCostPriceChange = (index, value) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].costPrice = Number(value);
    setCartItems(updatedCartItems);
  };

  const handleSalePriceChange = (index, value) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].salePrice = Number(value);
    setCartItems(updatedCartItems);
  };


  const applyOfficialMargins = () => {
    const updatedCartItems = cartItems.map(item => ({
      ...item,
      salePrice: calculateSalePrice(item)
    }));
    setCartItems(updatedCartItems);
  
    const newSalePrice = calculateNewPartSalePrice();
    setNewPart({ ...newPart, salePrice: newSalePrice });
  };
 
  const handleRemoveFromCart = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

    const addNewPartToCart = () => {
    if (!newPart.partName || !newPart.costPrice || !newPart.type) {
      toast.error('Please enter all details for the new part');
      return;
    }
    
    const newSalePrice = calculateNewPartSalePrice();
    
    if (isNaN(newSalePrice)) {
      toast.error('Invalid cost price');
      return;
    }
  
    setCartItems([...cartItems, { ...newPart, salePrice: newSalePrice }]);
    setNewPart({ partName: '', quantity: 1, costPrice: '', salePrice: '', type: 'generic' }); // Reset the form
  };
  

  const handleQuantityChange = (index, newQuantity) => {
    setCartItems(currentItems => {
        const newItems = [...currentItems];
        newItems[index] = { ...newItems[index], quantity: Number(newQuantity) };
        return newItems;
    });
};


const toggleModal = () => setIsModalOpen(!isModalOpen);


const finalizeQuotation = () => {
  if (newPart.partName && !cartItems.some(item => item.partName === newPart.partName)) {
    setCartItems([...cartItems, { ...newPart, salePrice: calculateNewPartSalePrice() }]);
  }

  // Reset newPart to initial state after adding to cartItems
  setNewPart({ partName: '', quantity: 1, costPrice: '', salePrice: '', type: 'generic' });
  
  toggleModal();
};


 // Prepare quotation details
 const quotationDetails = {
  items: cartItems,
  newItems: [newPart],
  subtotal: calculateTotals().totalCost,
  tax: calculateTotals().taxAmount,
  total: calculateTotals().totalSale
  
};

const customerDetails = {
  name: selectedJob?.customerName,
  address: selectedJob?.customerAddress,
  email: selectedJob?.customerEmail
  
};
 
  return (
    <>

< BreadCrumb pages={pages} />    
    <div className="container mx-auto p-4 bg-white shadow-md rounded">   
            <header className="mb-1">
                <h1 className="text-2xl font-bold text-gray-800 mb-2">Create New Quotation </h1>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                      <div className="bg-white rounded shadow p-4">
                      <label htmlFor="selectedJob" className="block font-medium text-gray-500 mb-2 px-4">Select Job Card</label> 
                                        <Select
                                            options={jobOptions}
                                            className='text-sm w-full'
                                            onChange={handleJobChange}
                                            value={selectedJob}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Select Job Card"
                                        />
                      </div>

                          <div className="bg-white rounded shadow p-4">
                          <JobDetails selectedJob={selectedJob} />
                          </div>

                  </div>
         </header>

               <main className="flex flex-wrap mt-4">

                         <section className="w-full lg:w-4/5 lg:pr-2">
                         <span className="px-1 text-sm text-gray-500"> 
                                    Sale Price is a markup on Cost Price. Highest Cost is readOnly and is for informational purposes only
                                </span> 
                            <CartItemsList 
                            cartItems={cartItems}
                            handleQuantityChange={handleQuantityChange}
                            handleCostPriceChange={handleCostPriceChange}
                            handleSalePriceChange={handleSalePriceChange}
                            handleTypeChange={handleTypeChange}
                            handleRemoveFromCart={handleRemoveFromCart}
                          />          
                                                     
                                    
                              {/* Seperator */}
                              <div className="flex items-center mb-2"> 
                                <hr className="flex-grow border-t border-gray-300" /> 
                                <span className="px-3 text-sm text-gray-500"> 
                                    Add additional items to the job card quotation below
                                </span> 
                                <hr className="flex-grow border-t border-gray-300" /> 
                            </div> 
                  
                            <NewItemForm newPart={newPart} setNewPart={setNewPart} addNewPartToCart={addNewPartToCart} services={services} />
                                
                                <div className="flex items-center space-x-4 mb-4">
                                  <div className="flex items-center">
                                    <label htmlFor="margin" className="mr-2 text-sm font-medium text-gray-700">
                                      Margin %:
                                    </label>
                                    <input
                                      type="number"
                                      id="margin"
                                      value={marginPercentage}
                                      onChange={(e) => setMarginPercentage(Number(e.target.value))}
                                      className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    />
                                  </div>
                                  <button
                                    onClick={applyOfficialMargins}
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                  >
                                    Apply Official Margins
                                  </button>
                                </div>
        
                          </section>

                                <aside className="w-full lg:w-1/5">
                                <TotalsDisplay 
                                  totals={totals}                                  
                                  includeTax={includeTax} 
                                  setIncludeTax={setIncludeTax} 
                                  finalizeQuotation={finalizeQuotation} 
                                />

                                <MarginAnalysis totals={totals} />
                                  </aside>
                      </main>
                     
                  <ToastContainer />

                          <QuotationModal
                      isOpen={isModalOpen}
                      totals={totals} 
                      onClose={toggleModal}
                      customerDetails={customerDetails}
                      quotationDetails={quotationDetails}
                      selectedJob={selectedJob}
                      newPart={newPart} 
                    />


    </div>
    </>
  );


};

export default QuotationPage;
