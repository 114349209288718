import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, updateDoc, collection, query, where, getDocs, getDoc, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatCurrency} from '../Help/helper';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import BreadCrumb from '../BreadCrumb';
import { generateInvoiceNumber } from './Utils/generateInvoiceNumber';

const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Quotations Admin', to: '/finance/quotations', component: Link, current: false }, 
  { name: 'Quotation Details', to: '/finance/quotations', component: Link, current: true },   
];


const QuotationDetails = () => {
  const { id } = useParams();
  const [quotation, setQuotation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'quotations', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuotation(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchData();
  }, [id]);


 

  const handleApproveQuotation = async () => {
    try {
      const uniqueInvoiceNumber = await generateInvoiceNumber(db);      
      // Update the quotation's approval status
      const quotationRef = doc(db, 'quotations', id);
      await updateDoc(quotationRef, {
        approvalStatus: "Yes"
      });
  
      // Update the job's approval status
      const jobsRef = collection(db, 'jobs');
      const q = query(jobsRef, where('jobCardNo', '==', quotation.jobCardNo));
      const querySnapshot = await getDocs(q);
  
      querySnapshot.forEach(async (docSnapshot) => {
        const jobRef = doc(db, 'jobs', docSnapshot.id);
        await updateDoc(jobRef, {
          approvalStatus: "Yes",
          approvalDate: new Date(),
        });
      });

      const totalreceivable = quotation.cartItems.reduce((total, item) => {
        return total + (item.salePrice * item.quantity);
      }, 0) + (quotation.taxAmount || 0);
     

      const invoiceData = {
        approvalStatus: "Yes", 
        customerEmail: quotation.customerEmail,
        approvalDate:Timestamp.fromDate(new Date()), 
        customerName: quotation.customerName,
        customerAddress: quotation.customerAddress, 
        jobCardNo : quotation.jobCardNo,
        commissionPaid: "No", 
        vehicleMake: quotation.vehicleMake,
        vehicleModel: quotation.vehicleModel,
        registrationNumber: quotation.registrationNumber,
        cartItems: quotation.cartItems.map(item => ({
          description: item.description,
          costPrice: item.costPrice,
          price: item.salePrice, 
          quantity: item.quantity,
          
        })),
        subtotal: quotation.subtotal,
        taxAmount: quotation?.taxAmount,
        invoiceNumber: uniqueInvoiceNumber,
        invoiceStatus: "Pending", 
        paymentsReceived: [], 
        totalSale: quotation.totalSale,
        datePosted: Timestamp.fromDate(new Date()), 
        receivable: totalreceivable,
        invoiceDate: Timestamp.fromDate(new Date()), 
        totalCost: quotation.totalCost,
        
      };
  
      // Create new invoice document in Firestore
      const docRef =  await addDoc(collection(db, 'newInvoices'), invoiceData);  
      toast.success('Quotation approved and invoice generated successfully');
      setTimeout(() => {
        navigate(`/finance/invoices/${docRef.id}`);
      }, 5000);  
    } catch (error) {
      console.error('Error approving quotation: ', error);
      toast.error('Error approving quotation.');
    }
  };
  

  if (!quotation) return null; 

  return (
    <>
<BreadCrumb pages={pages} />

    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Quotation #{quotation.jobCardNo}</h2>

      {quotation.approvalStatus === "Yes" ? (
        <div className="rounded bg-green-500 text-white text-sm py-1 px-2">
          Approved
        </div>
      ) : (
        <>
          <button
            type="button"
            onClick={() => navigate(`/finance/edit-quotation/${id}`)}
            className="rounded bg-yellow-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={handleApproveQuotation}
            className="rounded bg-green-500 ml-8 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
          >
            Approve Quotation and Raise Invoice
          </button>
        </>
      )}


      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Customer:</strong> <span className='font-semibold'> {quotation.customerName} </span></p>
        </div>
        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong> Email:</strong> <span className='text-sm'> {quotation?.customerEmail} </span></p>
        </div>

          <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Posted By:</strong> <span className='text-sm'> {quotation.createdBy} </span></p>
        </div>    

        
      
        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Date:</strong> <span className='text-sm'> {new Date(quotation.dateCreated?.seconds * 1000).toLocaleDateString()} </span></p>
        </div>
      </div>

      <div className="overflow-x-auto">
          <h3 className="font-bold mb-2 uppercase">Quotation Items</h3>
          <table className="w-full">
            <thead className="text-left border-b-2 border-gray-300">
              <tr>
               
                <th className="px-4 py-3">Description</th>
                <th className="px-4 py-3">Price</th>
                <th className="px-4 py-3">Quantity</th>
                <th className="px-4 py-3">Total</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {quotation.cartItems.map((item, index) => (
                <tr key={index}>
                  <td className="px-4 py-3">{item.description}</td>
                 <td className="px-4 py-3">{formatCurrency(item.salePrice)}</td>
                  <td className="px-4 py-3">{item.quantity}</td>
                  <td className="px-4 py-3">{formatCurrency(item.quantity * item.salePrice)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 text-right">
        <p className='uppercase text-gray-600 font-semibold'>Subtotal: {formatCurrency(quotation.subtotal)}</p>
        <p className='text-sm font-semibold text-gray-600'>7.5% VAT: {formatCurrency(quotation?.taxAmount)}</p>
        <h2 className="text-xl font-bold mb-2 uppercase">Grand Total: {formatCurrency(quotation.totalSale)}</h2>
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default QuotationDetails;
